
































import Vue from 'vue'
import { BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BCardCode from '@/vuexy/components/b-card-code/BCardCode.vue'
import { codeConfirm } from './code'

export default Vue.extend({
  components: {
    BButton,
    BCardCode,
  },
  directives: {
    Ripple,
  },
  setup(__, { root }) {
    // confirm texrt
    const confirmText = async () => {
      const {
        value = false,
      }: any = await root
        .$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })

      if (value) {
        await root.$swal({
          icon: 'success',
          title: 'Deleted!',
          text: 'Your file has been deleted.',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }
    }

    // comfirm button color
    const confirmButtonColor = async () => {
      const {
        value = false,
        dismiss = 'cancel',
      }: any = await root
        .$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          allowOutsideClick: false,
        })

      if (value) {
        await root.$swal({
          icon: 'success',
          title: 'Deleted!',
          text: 'Your file has been deleted.',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      } else if (dismiss === 'cancel') {
        await root.$swal({
          title: 'Cancelled',
          text: 'Your imaginary file is safe :)',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }
    }

    return {
      // data
      codeConfirm,

      // method
      confirmText,
      confirmButtonColor,
    }
  },
})
